import { EErrorMessage, urlRegex } from "@types";
import validator from "@utils/validate";
import { ZodType, z } from "zod";

interface ICustomSchema {
  field: string;
  type: ZodType;
}

const createSchema = (customSchema: ICustomSchema[]) => {
  let schemaObject: { [key: string]: ZodType } = {};
  for (const schema of customSchema) {
    schemaObject[schema.field] = schema.type;
  }
  return z.object(schemaObject);
};

const customSchemaAllergies: ICustomSchema[] = [];

const customSchemaIdCard: ICustomSchema[] = [];

const customSchemaProcedures: ICustomSchema[] = [];

const customSchemaHospitalizations: ICustomSchema[] = [];

const customSchemaToDos: ICustomSchema[] = [];

const customSchemaNotes: ICustomSchema[] = [];

const customSchemaQuestions: ICustomSchema[] = [];

const customSchemaVaccinations: ICustomSchema[] = [];

const customSchemaConditions: ICustomSchema[] = [];

const customSchemaMedications: ICustomSchema[] = [];

const customSchemaPractitioners: ICustomSchema[] = [
  {
    field: "patientPortal",
    type: z
      .string()
      .nullable()
      .optional()
      .refine((value) => !value || urlRegex.test(value), {
        message: EErrorMessage.LINK,
      }),
  },
  {
    field: "phone",
    type: z
      .string()
      .nullable()
      .optional()
      .refine((value) => !value || validator.validatePhoneNumber(value), {
        message: EErrorMessage.PHONE,
      }),
  },
  {
    field: "fax",
    type: z
      .string()
      .nullable()
      .optional()
      .refine((value) => !value || validator.validatePhoneNumber(value), {
        message: EErrorMessage.FAX,
      }),
  },
  {
    field: "email",
    type: z.string().email().nullable().optional().or(z.literal('')),
  },
  {
    field: "multipleLocations",
    type: z.array(z.string().min(1, "Location is not empty"))
  }
];

const customSchemaFile: ICustomSchema[] = [
  { field: "isFollowedUp", type: z.boolean() },
];

const schemaAllergies = createSchema(customSchemaAllergies);
const schemaIdCard = createSchema(customSchemaIdCard);
const schemaProcedures = createSchema(customSchemaProcedures);
const schemaHospitalizations = createSchema(customSchemaHospitalizations);
const schemaNotes = createSchema(customSchemaNotes);
const schemaQuestions = createSchema(customSchemaQuestions);
const schemaVaccinations = createSchema(customSchemaVaccinations);
const schemaConditions = createSchema(customSchemaConditions);
const schemaMedications = createSchema(customSchemaMedications);
const schemaFile = createSchema(customSchemaFile);
const schemaPractitioners = createSchema(customSchemaPractitioners);
const schemaToDos = createSchema(customSchemaToDos);

export {
  schemaAllergies,
  schemaConditions,
  schemaFile,
  schemaHospitalizations,
  schemaIdCard,
  schemaMedications,
  schemaNotes,
  schemaProcedures,
  schemaQuestions,
  schemaVaccinations,
  schemaPractitioners,
  schemaToDos,
};

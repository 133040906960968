import { FileTextOutlined } from "@ant-design/icons";
import { ProfileSummaryIcon } from "@assets/icons";
import {
  KAnchor,
  KButton,
  KCheckBox,
  KLgText700,
  KMdText,
  KMdText700,
  KModalExportProgress,
  KSwitch,
  KText,
  SelectionCardContainer,
} from "@components";
import { KModalQuickAdd } from "./ModalQuickAdd";
import { heightFooter, heightHeader } from "@constants/size";
import { useKContext } from "@context";
import { exportPDFUserService } from "@services";
import { ECardType, IExportPDFDto } from "@types";
import { infoCardTypes } from "@utils/index";
import { Divider, Flex, message, ModalProps, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

type IProps = {
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  icon?: ReactNode;
  title?: ReactNode;
  isPrimary?: boolean;
  children?: ReactNode;
};

type ContentModalContainerProps = {
  $isMobile?: boolean;
};

const SwitchContainer = styled.label({
  display: "flex",
  justifyContent: "space-between",
  flex: 1,
  gap: 8,
});

const ContentModalContainer = styled(Flex)<ContentModalContainerProps>(() => ({
  gap: 16,
  height: "auto",
  maxHeight: `calc(100vh - ${heightHeader + heightFooter}px)`,
}));

const ExportInfoCard: FC<IProps> = ({
  icon,
  checked,
  onChange,
  title,
  isPrimary,
  children,
}) => {
  return (
    <SelectionCardContainer $isActive={checked} $isPrimary={isPrimary}>
      <Space align="center">
        {icon && <Flex align="center">{icon}</Flex>}
        <KLgText700 $ellipsis>{title}</KLgText700>
      </Space>
      {children}
      <KCheckBox checked={checked} onChange={onChange} />
    </SelectionCardContainer>
  );
};

export const ModalExportPDF: React.FC<ModalProps> = ({ open, onCancel }) => {
  const { topics, profileId, isMobile } = useKContext();

  const [isCertainInfo, setIsCertainInfo] = useState(true);
  const [isExportSummary, setIsExportSummary] = useState(false);
  const [isExportProfileBasic, setIsExportProfileBasic] = useState(false);
  const [infoTypeExports, setInfoTypeExports] = useState<ECardType[]>([]);
  const [topicIdsExports, setTopicIdsExports] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openConfirmExport, setOpenConfirmExport] = useState(false);

  const isDisableExportButton = useMemo(
    () =>
      isCertainInfo &&
      !isExportSummary &&
      !isExportProfileBasic &&
      !infoTypeExports.length &&
      !topicIdsExports.length,
    [
      isCertainInfo,
      isExportSummary,
      isExportProfileBasic,
      infoTypeExports,
      topicIdsExports,
    ]
  );

  const handleSelectAllInfoTypes = useCallback(() => {
    setInfoTypeExports(infoCardTypes.map(({ cardType }) => cardType));
  }, []);

  const handleSelectAllTopics = useCallback(() => {
    setTopicIdsExports(topics.map(({ _id }) => _id));
  }, [topics]);

  const handleStartExport = useCallback(async () => {
    if (!profileId) return;

    try {
      setIsSubmitting(true);

      let payload: IExportPDFDto;
      if (isCertainInfo) {
        payload = {
          isExportProfileBasic,
          summary: isExportSummary,
          cardTypes: infoTypeExports,
          topics: topicIdsExports,
        };
      } else {
        payload = {
          isExportProfileBasic: true,
          summary: true,
          cardTypes: infoCardTypes.map(({ cardType }) => cardType),
          topics: topics.map(({ _id }) => _id),
        };
      }

      await exportPDFUserService(profileId, payload);
      setOpenConfirmExport(true);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    isCertainInfo,
    profileId,
    isExportProfileBasic,
    isExportSummary,
    infoTypeExports,
    topicIdsExports,
    topics,
  ]);

  const onOkExportProgress = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setIsCertainInfo(true);
      setIsExportSummary(false);
      setIsExportProfileBasic(false);
      setInfoTypeExports([]);
      setTopicIdsExports([]);
      setOpenConfirmExport(false);
      onCancel?.(e);
    },
    [onCancel]
  );

  return (
    <>
      <KModalExportProgress
        open={openConfirmExport}
        onOk={onOkExportProgress}
      />
      <KModalQuickAdd
        open={open}
        title="Export to PDF"
        onCancel={onCancel}
        width={600}
      >
        <ContentModalContainer vertical>
          <KLgText700>Export this profile to a PDF</KLgText700>

          <KText>
            It may take some time for the server to prepare your information.
            Once the PDF is ready, you will receive a Notification in-app with a
            link to download your PDF to your device.
          </KText>

          <SwitchContainer>
            <KMdText>Only certain information</KMdText>
            <KSwitch value={isCertainInfo} onChange={setIsCertainInfo} />
          </SwitchContainer>

          {isCertainInfo ? (
            <Flex
              gap={16}
              style={{ overflow: "scroll", scrollbarWidth: "none" }}
              vertical
            >
              <Flex vertical gap={8}>
                <ExportInfoCard
                  isPrimary
                  icon={<ProfileSummaryIcon />}
                  title="Profile Basics"
                  checked={isExportProfileBasic}
                  onChange={(e) => setIsExportProfileBasic(e.target.checked)}
                />
                <ExportInfoCard
                  isPrimary
                  icon={<FileTextOutlined />}
                  title="Summary"
                  checked={isExportSummary}
                  onChange={(e) => setIsExportSummary(e.target.checked)}
                />
              </Flex>

              <Flex gap={8} vertical={isMobile}>
                <Flex vertical gap={8} flex={1}>
                  <Flex justify="space-between">
                    <KMdText700>Info Types</KMdText700>
                    <KAnchor onClick={handleSelectAllInfoTypes}>
                      Select All
                    </KAnchor>
                  </Flex>

                  {infoCardTypes.map(({ icon, title, cardType }) => (
                    <ExportInfoCard
                      key={cardType}
                      icon={icon}
                      title={title}
                      checked={infoTypeExports.includes(cardType)}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked) {
                          return setInfoTypeExports((prev) => [
                            ...prev,
                            cardType,
                          ]);
                        }
                        setInfoTypeExports((prev) => [
                          ...prev.filter((c) => c !== cardType),
                        ]);
                      }}
                    />
                  ))}
                </Flex>

                {!isMobile && (
                  <Divider type="vertical" style={{ height: "auto" }} />
                )}

                <Flex vertical gap={8} flex={1}>
                  <Flex justify="space-between">
                    <KMdText700>Topics</KMdText700>
                    <KAnchor onClick={handleSelectAllTopics}>
                      Select All
                    </KAnchor>
                  </Flex>

                  {topics.map(({ title, _id }) => (
                    <ExportInfoCard
                      key={_id}
                      title={title}
                      checked={topicIdsExports.includes(_id)}
                      onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked) {
                          return setTopicIdsExports((prev) => [...prev, _id]);
                        }
                        setTopicIdsExports((prev) => [
                          ...prev.filter((c) => c !== _id),
                        ]);
                      }}
                    />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          ) : null}

          <KButton
            title="Start Export"
            size="large"
            disabled={isDisableExportButton}
            onClick={handleStartExport}
            loading={isSubmitting}
          />
        </ContentModalContainer>
      </KModalQuickAdd>
    </>
  );
};

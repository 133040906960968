import { ButtonAddCard, KFormUser, KLgText700 } from "@components";
import { QuestionItem } from "@pages/card-list/components/item";
import { getCardDetailsService } from "@services";
import {
  ECardType,
  ECardTypeName,
  ICardAppointmentRes,
  ICardPractitionerRes,
  IKSelectOption,
  IParams,
} from "@types";
import { Divider, Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const AppointmentDetail = () => {
  const {
    getValues,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useFormContext<ICardAppointmentRes>();
  const { profileId } = useParams<IParams>();
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [options, setOptions] = useState<IKSelectOption[]>([]);
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);

  useEffect(() => {
    const getLocation = async () => {
      const practitionerId = getValues("practitioner");
      if (!profileId || !practitionerId) return;
      if(dirtyFields['practitioner']) {
        setIsOpenSelect(true);
      }
      const practitioner = await getCardDetailsService<ICardPractitionerRes>(
        profileId,
        ECardTypeName.Practitioners,
        practitionerId
      );
      const locationsMapped = practitioner.multipleLocations?.reduce(
        (acc: { value: string; label: string }[], value: string) => {
          acc.push({ value, label: value });
          return acc;
        },
        []
      );
      setIsShowDropdown(true);
      setOptions(locationsMapped);
      if (practitioner) {
        if (practitioner.location) {
          setValue("location", practitioner.location);
        } else if (!!practitioner.multipleLocations?.length) {
          setValue("multipleLocations", []);
        }
      }
    };

    getLocation();
  }, [getValues("practitioner")]);


  const shouldShowDropdown = useMemo(() => {
    return isShowDropdown && watch("practitioner") && getValues("multipleLocations");
  }, [isShowDropdown, watch("practitioner"), getValues("multipleLocations")]);
  

  const questions = useMemo(() => getValues("questions"), [watch("questions")]);
  return (
    <>
      <KFormUser.DateTimePicker label="Date" name="startTime" />
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      {shouldShowDropdown && (
        <KFormUser.Select
          open={isOpenSelect}
          label="Practitioner location"
          name="multipleLocations"
          placeholder="Select practitioner location"
          options={options}
          onChange={(selectedValue) => {
            if (selectedValue) {
              setValue("location", selectedValue);
              setIsOpenSelect(false)
            }
          }}
          onDropdownVisibleChange={(open) => {
            if (open !== isOpenSelect) {
              setIsOpenSelect(open);
            }
          }}
        />
      )}
      <KFormUser.TextArea label="Location" name="location" />
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
      <Divider />
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="center">
          <KLgText700>Active Questions</KLgText700>
          <ButtonAddCard cardType={ECardType.Questions} />
        </Flex>
        {questions?.map((qs) => (
          <QuestionItem card={qs} key={qs._id} isDetail />
        ))}
      </Flex>
    </>
  );
};

import { Color } from "@types";
import { Radio } from "antd";
import styled from "styled-components";

const RadioGroupItem = styled(Radio.Button)`
  flex: 1;
  text-align: center;
  &.ant-radio-button-wrapper:last-child {
    white-space: nowrap;
  }
  &.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: ${Color.PrimaryBase};
    background-color: ${Color.Secondary04};
    border: 1px solid ${Color.PrimaryBase};
  }
`;

export { RadioGroupItem };

import { KFormUser } from "@components";
import { specialtyTypeOptions } from "@constants/options";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const PractitionersDetail = () => {
  return (
    <>
      <KFormUser.Select
        label="Specialty"
        name="specialty"
        options={specialtyTypeOptions}
      />
      <Flex gap={16} justify="space-between">
        <KFormUser.DatePicker label="Started Seeing" name="startTime" />
        <KFormUser.EndDatePicker label="Stopped Seeing" name="endTime" />
      </Flex>
      <KFormUser.Switch label="No longer seeing?" name="isNoLongerSeeing" />
      <KFormUser.InputPhone
        placeholder="###-###-####"
        label="Phone"
        name="phone"
        phone
        addonBefore="+1"
      />
      <KFormUser.InputPhone
        placeholder="###-###-####"
        label="Fax"
        name="fax"
        addonBefore="+1"
      />
      <KFormUser.InputEmail
        label="Email"
        name="email"
        placeholder="Enter email"
      />
      <KFormUser.InputLocation
        label="Location(s)"
        placeholder="Enter location info"
        name="multipleLocations"
        content="Add Location"
      />
      <KFormUser.InputLink
        label="Patient Portal"
        name="patientPortal"
        placeholder="Enter link"
      />
      <KFormUser.TextArea
        name="nurse"
        label="Nurse(s)"
        placeholder="Enter nurse info"
      />
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
    </>
  );
};

import {
  KCollapse,
  KFormGroupUser,
  KFormUser,
  KInput,
  KRadioGroupSexBirth,
} from "@components";
import { KContext } from "@context";
import { EPronouns, SSN_LENGTH, genders } from "@types";
import { formatSNN, handleMapOptionsPronous } from "@utils/index";
import { Divider, Flex, Space } from "antd";
import { useContext, useMemo } from "react";
import { Controller } from "react-hook-form";

export const PersonalInfo = () => {
  const { isMobile } = useContext(KContext);
  const pronouns =  useMemo(() => {
    return handleMapOptionsPronous(EPronouns);
  },[]);
  return (
    <KCollapse title="Personal Info">
      <Divider style={{ margin: 0, marginTop: -8 }} />
      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", marginTop: 16 }}
      >
        <KFormUser.Input label="First Name" name="basicInformation.firstName" />
        <KFormUser.Input
          label="Middle Name"
          name="basicInformation.middleName"
        />
        <KFormUser.Input label="Last Name" name="basicInformation.lastName" />
        <KFormUser.DatePicker
          format="US_DATE"
          label="Date of Birth"
          name="basicInformation.birthDate"
        />
        <Flex vertical={isMobile} justify="space-between" gap={16}>
          <Flex vertical gap={8} style={{ width: isMobile ? "100%" : "50%" }}>
            <KFormUser.Select
              name="basicInformation.pronouns"
              label="Pronouns Used"
              options={pronouns}
            />
          </Flex>
          <Flex vertical gap={8} style={{ width: isMobile ? "100%" : "50%" }}>
            <KFormGroupUser
              label="Sex Assigned at Birth"
              name="basicInformation.sex"
            >
              <KRadioGroupSexBirth name="basicInformation.sex" />
            </KFormGroupUser>
          </Flex>
        </Flex>
        <KFormUser.Select
          name="basicInformation.gender"
          label="Gender"
          options={genders}
        />
        <KFormGroupUser label="SSN" name="basicInformation.SSN">
          <Controller
            name="basicInformation.SSN"
            render={({ field }) => (
              <KInput
                {...field}
                maxLength={SSN_LENGTH}
                placeholder="###-##-####"
                onChange={(e) => field.onChange(formatSNN(e.target.value))}
              />
            )}
          />
        </KFormGroupUser>
      </Space>
    </KCollapse>
  );
};

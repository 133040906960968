import { Flex, Input, InputRef, message } from "antd";
import { FC, useEffect, useRef, useState } from "react";

import { useKContext } from "@context";
import { useLoggingService } from "@hooks";
import { createTopicService } from "@services";
import { KLgText700Secondary88, KSmTextSecondary50 } from "components/Text";
import { StyledModal } from "./ModalAddCard";
import { ICardItemRes } from "@types";

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  handleAfterCreate?: (topicId: string) => Promise<void>;
}

export const ModalAddTopic: FC<IProps> = ({
  isOpen,
  onCancel,
  handleAfterCreate,
}) => {
  const { profileId, fetchTopics } = useKContext();
  const { pushInfoTypeLog } = useLoggingService();
  const inputRef = useRef<InputRef>(null);
  const [topicName, setTopicName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { current } = inputRef;
    if (current && isOpen) current.focus();
  }, [isOpen]);

  const handleCreateTopic = async () => {
    try {
      setIsLoading(true);
      const res = await createTopicService(profileId, topicName);
      await fetchTopics();
      await handleAfterCreate?.(res._id);
      pushInfoTypeLog("CREATED", res, "TOPIC");
      message.success('Create new topic successfully!');
      setTopicName('');
      onCancel();
    } catch (error: any) {
      message.error(error?.response?.data?.message || 'Create new topic failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledModal
      title={<KSmTextSecondary50>Add New Topic</KSmTextSecondary50>}
      width={360}
      centered
      open={isOpen}
      onOk={handleCreateTopic}
      onCancel={() => {
        setTopicName('');
        onCancel();
      }}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: !topicName }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Flex vertical gap={8}>
        <KLgText700Secondary88>
          Give your topic name:
        </KLgText700Secondary88>
        <Input
          ref={inputRef}
          value={topicName}
          onChange={(e) => { setTopicName(e.target.value); }}
          onPressEnter={handleCreateTopic}
        />
      </Flex>
    </StyledModal>
  );
};

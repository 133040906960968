import { apiInstance } from "@apis";
import { VERIFY_PIN_ENDPOINT } from "@constants/url";
import { getListParamsDefault } from "@services";
import {
  ApiError,
  ECardType,
  ECardTypeName,
  EContactType,
  ETodoType,
  IAssignedRes,
  IAttachment,
  IAvatarRes,
  IBasePagableRes,
  IBaseRes,
  ICardItemDto,
  ICardQuery,
  ICardTagRes,
  ICardTodoDetail,
  ICombinedCard,
  IContactInfoPayload,
  ICreateProfile,
  INotificationItem,
  IPagable,
  IProfileBasic,
  IProfileSwitch,
  IRelevantInfoRes,
  IResSearchInfoType,
  ISummaryCard,
  ITemporaryShareInfoUser,
  IUpcomingRes,
  IUserAccessProfile,
} from "@types";
import { createRandomUUID } from "@utils/storage";
import { RcFile } from "antd/es/upload";
import moment from "moment";
import { UserInfo } from "services/auth";

export enum TYPE_THIRD_PARTY {
  GOOGLE = "google",
  APPLE = "apple",
}

const baseUrl = "/v1/profile";

export const getProfileDetail = async (profileId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IProfileBasic>>(
    `${baseUrl}/${profileId}`
  );
  return data?.data;
};

export const createProfileDetail = async (profile: ICreateProfile) => {
  const { data } = await apiInstance.post<IBaseRes<IProfileBasic>>(
    `/v1/profile`,
    profile,
    { params: { hasCreateGuide: true } }
  );
  return data?.data;
};

export const updateProfileDetail = async (
  profileId: string,
  profile: IProfileBasic
) => {
  const { data } = await apiInstance.put<IBaseRes<IProfileBasic>>(
    `${baseUrl}/${profileId}`,
    profile
  );
  return data?.data;
};

export const uploadAvatarProfile = async (
  profileId: string,
  avatar: RcFile
) => {
  const fileId = createRandomUUID();
  const file = new FormData();
  file.append("image", avatar);
  const { data } = await apiInstance.put<IBaseRes<IAvatarRes>>(
    `${baseUrl}/${profileId}/avatar`,
    file,
    {
      headers: { "Content-Type": "multipart/form-data" },
      params: { fileId },
    }
  );
  return data?.data;
};

export const getSwitchProfiles = async (page: number) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IProfileSwitch>>>(
    `/v1/profile/switch`,
    { params: { page, size: 20 } }
  );
  return data?.data;
};

export const getSummaryCardService = async (
  profileId: string,
  topics?: string
): Promise<ISummaryCard[]> => {
  const { data } = await apiInstance.get(
    `${baseUrl}/${profileId}/card/summary`,
    { params: topics ? { topics: topics } : {} }
  );
  return data?.data;
};

export const getProfileByIdService = async (
  profileId: string
): Promise<IProfileBasic> => {
  const { data } = await apiInstance.get(`${baseUrl}/${profileId}`);
  return data?.data;
};

export const getListCardsService = async <T,>(
  profileId: string,
  cardType: ECardTypeName,
  query?: ICardQuery
) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<T>>>(
    `${baseUrl}/${profileId}/card/${cardType}`,
    { params: { size: 1000, ...query } }
  );
  return data?.data?.result;
};

export const getCardDetailService = async <T,>(
  profileId: string,
  cardType: ECardTypeName,
  id: string
) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(
    `${baseUrl}/${profileId}/card/${cardType}/${id}`
  );
  return data?.data;
};

export const getCardDetailsService = async <T,>(
  profileId: string,
  cardType: ECardTypeName,
  cardId: string
) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(
    `${baseUrl}/${profileId}/card/${cardType}/${cardId}`
  );
  return data?.data;
};

export const updateCardService = async <T,>(
  profileId: string,
  cardType: ECardTypeName,
  cardId: string,
  payload: T
) => {
  const { data } = await apiInstance.put<IBaseRes<T>>(
    `${baseUrl}/${profileId}/card/${cardType}/${cardId}`,
    payload
  );
  return data?.data;
};

export const uploadFileCardDetail = async (
  profileId: string,
  cardType: ECardTypeName,
  cardId: string,
  file: RcFile
) => {
  const fileId = createRandomUUID();
  const fileUpload = new FormData();
  fileUpload.append("file", file);
  const { data } = await apiInstance.post<IBaseRes<IAttachment>>(
    `/v1/profile/${profileId}/card/${cardType}/${cardId}/upload`,
    fileUpload,
    {
      headers: { "Content-Type": "multipart/form-data" },
      params: { fileId },
    }
  );
  return data;
};

export const deleteAtmCardDetail = async (profileId: string, id: string) => {
  const { data } = await apiInstance.delete<IBaseRes<IAttachment>>(
    `/v1/profile/${profileId}/file/${id}`
  );
  return data;
};

export const updateNameAtm = async (
  profileId: string,
  id: string,
  originalName: string
) => {
  const { data } = await apiInstance.put<IBaseRes<IAttachment>>(
    `/v1/profile/${profileId}/file/${id}`,
    { originalName }
  );
  return data?.data;
};

export const createCardDetail = async (
  profileId: string,
  cardType: ECardTypeName,
  payload: ICardItemDto
) => {
  const { data } = await apiInstance.post<IBaseRes<ICombinedCard>>(
    `/v1/profile/${profileId}/card/${cardType}`,
    payload
  );
  return data?.data;
};

export const getListAddToCardService = async (
  profileId: string,
  cardType: ECardTypeName,
  search = ""
) => {
  const { data } = await apiInstance.get<IBaseRes<ICardTagRes[]>>(
    `${baseUrl}/${profileId}/card/${cardType}/list-add-to-card`,
    { params: { search } }
  );
  return data;
};

export const getUsersAccessService = async (profileId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IUserAccessProfile[]>>(
    `${baseUrl}/${profileId}/user`
  );
  return data;
};

export const requestHelpToDoService = async (profileId: string, id: string) => {
  const { data } = await apiInstance.put<ApiError>(
    `${baseUrl}/${profileId}/card/to-do/${id}/help`
  );
  return data;
};

export const getLinkedInfoToDoService = async (
  profileId: string,
  cardId: string
) => {
  const { data } = await apiInstance.get<IBaseRes<[]>>(
    `${baseUrl}/${profileId}/card/to-do/${cardId}/linked-info-cards`
  );
  return data;
};

export const searchCardsTitleService = async (
  profileId: string,
  ids: string[],
  search = ""
) => {
  const cardIds = ids?.join(",");
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardTagRes>>>(
    `${baseUrl}/${profileId}/cards/search`,
    { params: { ...getListParamsDefault, search, cardIds } }
  );
  return data;
};

export const assignOrClaimToDoService = async (
  profileId: string,
  cardId: string,
  type: ETodoType,
  ids?: string[]
) => {
  const { data } = await apiInstance.put<IBaseRes<IAssignedRes>>(
    `${baseUrl}/${profileId}/card/to-do/${cardId}/type/${type}`,
    { ids }
  );
  return data?.data;
};

export const toggleCompleteToDo = async (
  profileId: string,
  cardId: string,
  payload: { comment: string; isCompleted: boolean }
) => {
  const { data } = await apiInstance.put<IBaseRes<ICardTodoDetail>>(
    `${baseUrl}/${profileId}/card/to-do/${cardId}/toggle-complete`,
    payload
  );
  return data;
};
export const getUserRelevantPractitioners = async (
  profileId: string,
  practitionerId: string
) => {
  const { data } = await apiInstance.get<IBaseRes<IRelevantInfoRes[]>>(
    `${baseUrl}/${profileId}/card/practitioner/${practitionerId}/relevant-info`
  );
  return data;
};

export const getListUserHaveAccessToProfile = async <T,>(
  profileId: string,
  status: string
) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(
    `${baseUrl}/${profileId}/user`,
    { params: { status } }
  );
  return data;
};

export const getCountTempShareHasCardType = async <T,>(
  profileId: string,
  cardType: ECardType
) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(
    `${baseUrl}/${profileId}/temporary-share/count`,
    { params: { cardType } }
  );
  return data?.data;
};

export const deleteCardDetail = async <T,>(
  profileId: string,
  cardType: string,
  id: string
) => {
  if (cardType === ECardTypeName.File) cardType = ECardTypeName.FileGroup;
  const { data } = await apiInstance.delete<IBaseRes<T>>(
    `${baseUrl}/${profileId}/card/${cardType}/${id}`
  );
  return data;
};

export const updateProfileNotificationsSetting = async <T,>(
  profileId: string
) => {
  const { data } = await apiInstance.put<T>(
    `${baseUrl}/${profileId}/setting-notifications`
  );
  return data;
};

export const getCountNotificationService = async () => {
  const { data } = await apiInstance.get<IBaseRes<number>>(
    `v1/notification/count`
  );
  return data?.data;
};

export const getAllNotificationsService = async (page = 1) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<INotificationItem>>>(
    `v1/notification`,
    { params: { page, size: 10 } }
  );
  return data?.data;
};

export const markAsAllReadNotificationsService = async () => {
  await apiInstance.put(`v1/notification`);
};

export const markAsReadOneNotificationService = async (id: string) => {
  await apiInstance.put(`v1/notification/${id}`);
};

export const deleteProfile = async (profileId: string) => {
  const { data } = await apiInstance.delete<IBaseRes<IProfileBasic>>(
    `${baseUrl}/${profileId}`
  );
  return data;
};

export const updateRelationshipService = async (
  profileId: string,
  userId: string,
  relationship: string
) => {
  const { data } = await apiInstance.put(
    `/v1/relationship/profile/${profileId}/user/${userId}`,
    {
      relationship,
    }
  );
  return data;
};

export const saveDeviceTokenFCM = async (token: string) => {
  const { data } = await apiInstance.post<IBaseRes<boolean>>(
    `/v1/user/fcm-token?token=${token}`
  );
  return data;
};

export const getUpcomingService = async (profileId: string) => {
  const limitTime = moment().add(7, "days").toDate();
  const { data } = await apiInstance.get<IBasePagableRes<IUpcomingRes>>(
    `${baseUrl}/${profileId}/upcoming`,
    { params: { size: 1000, version: "New", limitTime } }
  );
  return data?.data?.result;
};

export const getRecentUpdatesService = async (
  profileId: string,
  page?: number
) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICombinedCard>>>(
    `${baseUrl}/${profileId}/recent-updates`,
    { params: { page, size: 3 } }
  );
  return data?.data;
};

export const updateUserInfoService = async (payload: UserInfo) => {
  const { data } = await apiInstance.put<IBaseRes<UserInfo>>(
    `/v1/user`,
    payload
  );
  return data?.data;
};

export const uploadAvatarUserService = async (avatar: RcFile) => {
  const fileId = createRandomUUID();
  const file = new FormData();
  file.append("image", avatar);
  const { data } = await apiInstance.put<IBaseRes<IAvatarRes>>(
    `/v1/auth/avatar`,
    file,
    {
      headers: { "Content-Type": "multipart/form-data" },
      params: { fileId },
    }
  );
  return data?.data;
};

export const requestOTPUpdateService = async (
  contact: string,
  type: EContactType
) => {
  const { data } = await apiInstance.get(`/v1/auth/update-otp/${contact}`, {
    params: { type },
  });
  return data;
};

export const updateUserContactService = async (
  payload: IContactInfoPayload,
  code: string
) => {
  const { data } = await apiInstance.put<
    IBaseRes<UserInfo & { accessToken?: string }>
  >(`/v1/user/contact-info?code=${code}`, payload);
  return data?.data;
};

export const searchCardInfoTypeService = async (
  profileId: string,
  keyword = ""
) => {
  const { data } = await apiInstance.post<IBaseRes<IResSearchInfoType<any>[]>>(
    `${baseUrl}/${profileId}/search?keyword=${keyword}`
  );
  return data?.data;
};

export const verifyPinService = async (pin: string) => {
  await apiInstance.post(VERIFY_PIN_ENDPOINT, { pin });
};

export const getTempShareOfUser = async () => {
  const { data } = await apiInstance.get<IBaseRes<ITemporaryShareInfoUser>>(
    `/v1/temporary-share/user`
  );
  return data?.data;
};

export const deleteAccountService = async () => {
  await apiInstance.delete(`/v1/user`);
};

export const resetPinService = async () => {
  const { data } = await apiInstance.put(`v1/user/reset-pin`);
  return data;
};

export * from "./logging";
export * from "./topic";
export * from "./export";

import { apiInstance, fastenHeathApiInstance } from "@apis";
import {
  ECardType,
  EMRExportStatus,
  IBaseRes,
  IFastenHealthSearchResponse,
  IMedicalPagable,
  IMedicalRecordImport
} from "@types";
import { getToken } from "@utils/storage";

const baseUrl = "https://api.connect.fastenhealth.com/v1/bridge";

interface IInitiateConnectFTH {
  endpoint_id: string;
  brand_id: string;
  portal_id: string;
  external_id: string;
}

export interface ICardImport {
  _id: string;
  title: string;
  startTime?: string;
  source: string;
  referenceName?: string;
  isImported: boolean;
  medExportId: string;
}

export interface IMedicalRecord {
  _id: string;
  createdTime?: string;
  updatedTime?: string;
  lastUpdateDataTime: string | null;
  downloadLink: string;
  medicalSource: string;
  profile: string;
  orgConnectionId: string;
  status: EMRExportStatus;
  importedTime?: string;
  description?: string;
  data?: {
    cardType: ECardType;
    items: ICardImport[];
  }[];
}

export const searchMedicalSourcesService = async (
  query: string | undefined,
  searchAfter: (number | string)[]
) => {
  const api_mode = process.env.REACT_APP_FASTEN_HEALTH_API_MODE;
  const { data } = await fastenHeathApiInstance.post<
    IBaseRes<IFastenHealthSearchResponse>
  >(
    `${baseUrl}/catalog/search`,
    {
      query,
      searchAfter: searchAfter.map((val) => val.toString()),
    },
    { params: { api_mode } }
  );
  return data?.data?.hits;
};

export const getPublicIdFTHService = async (profileId: string) => {
  const { data } = await apiInstance.get<IBaseRes<string>>(
    `/v1/profile/${profileId}/medical-record/public-id`
  );
  return data?.data;
};

export const initiateConnectFTHService = async ({
  ...props
}: IInitiateConnectFTH) => {
  const { external_id } = props;
  const public_id = await getPublicIdFTHService(external_id);
  const params = new URLSearchParams({ ...props, public_id });
  return `${baseUrl}/connect?${params.toString()}`;
};

export const requestExportMedicalRecordService = async (
  profileId: string,
  orgConnectionId: string,
  brandId: string,
  isSyncAgain: boolean = false
) => {
  await apiInstance.post(
    `/v1/profile/${profileId}/medical-record/export`,
    {
      orgConnectionId,
      brandId,
    },
    {
      headers: { Authorization: `Bearer ${getToken("WebApp")}` },
      params: { isSyncAgain },
    }
  );
};

export const getDetailMedicalRecordService = async (
  profileId: string,
  orgConnectionId: string
) => {
  const { data } = await apiInstance.get<IBaseRes<IMedicalRecord>>(
    `/v1/profile/${profileId}/medical-record/org-connection/${orgConnectionId}`
  );
  return data?.data;
};

export const getListMedicalRecordProfileService = async (profileId: string) => {
  const { data } = await apiInstance.get<
    IBaseRes<IMedicalPagable<IMedicalRecordImport>>
  >(`/v1/profile/${profileId}/medical-record`);
  return {
    result: data.data.result,
    availableImports: data.data.availableImports,
  };
};

export const importCardsFromMedicalRecord = async (
  profileId: string,
  medRecordId: string,
  exportIds: string[]
) => {
  const url = `/v1/profile/${profileId}/medical-record/${medRecordId}/import`;
  await apiInstance.post(url, { exportIds });
};
